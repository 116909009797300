export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "appProduct",
      meta: {
        title: "QUẢN LÝ SẢN PHẨM",
        icon: "ri-archive-line",
        name: "Sản phẩm",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "SẢN PHẨM",
      },
      component: () =>
        import("../app_product/pages/manage_product/ManageProduct.vue"),
    },
    {
      path: "/",
      name: "appProduct",
      meta: {
        title: "QUẢN LÝ SẢN PHẨM",
        icon: "ri-archive-line",
        name: "Sản phẩm",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "SẢN PHẨM",
      },
      component: () =>
        import("../app_product/pages/manage_product/ManageProduct.vue"),
    },
    {
      path: "/type",
      name: "manageProductSubsytem",
      meta: {
        title: "QUẢN LÝ PHÂN LOẠI SẢN PHẨM",
        icon: "ri-pages-line",
        name: "Phân loại",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "SẢN PHẨM",
      },
      component: () =>
        import("@/app_product/pages/manage_product_subsystem/manage.vue"),
    },
    {
      path: "/tags",
      name: "manageProductTags",
      meta: {
        title: "QUẢN LÝ TAGS SẢN PHẨM",
        icon: "ri-pages-line",
        name: "Tags",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "SẢN PHẨM",
      },
      component: () =>
        import(
          "../app_product/pages/manage_product_tags/manage.vue"
        ),
    },
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
