
import ManageMaterialAreaRouter from './manage_material_area/router.js'

export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    ...ManageMaterialAreaRouter('material'),
    {
      path: "/",
      name: "manageCooperativeStaff",
      meta: {
        title: "Thông tin hợp tác xã",
        icon: 'ri-pages-line',
        name: "Hợp tác xã",
        showMenu: false,
        showNavSubMenu: false,
        belongTo: "manage"
      },
      props: {},
      component: () => import("@/app_cooperative/pages/manage_coop_staff/Cooperative.vue"),
    },
    {
      path: "/detail/:id",
      name: "manageCooperativePublic",
      meta: {
        title: "Thông tin hợp tác xã",
        icon: 'ri-pages-line',
        name: "Hợp tác xã",
        showMenu: false,
        showNavSubMenu: false,
        belongTo: "manage"
      },
      props: {},
      component: () => import("@/app_cooperative/pages/manage_coop_staff/Cooperative.vue"),
    },
    {
      path: "/update",
      name: "manageCooperativeEditStaff",
      meta: {
        title: "Cập nhật thông tin hợp tác xã",
        icon: 'ri-pages-line',
        name: "Hợp tác xã",
        showMenu: false,
        showNavSubMenu: false,
        belongTo: "manage"
      },
      props: {},
      component: () => import("@/app_cooperative/pages/manage_coop_staff/CooperativeEdit.vue"),
    },
  ];

  ROUTER_DATA_MANAGE.map(router => {
    router.path = url_root ? '/' + url_root + router.path : router.path
  })
  return ROUTER_DATA_MANAGE
}
