import Token from "../../helpers/user/user";
import API from "../../helpers/api/useAxios.js";
import { setPermissionApp } from "../../state/dataUser.js";

const checkLogout = (isPublic, next) => {
  Token().removeUser();
  if (isPublic) {
    next({ name: "Home" }); // Chuyển hướng đến trang chính
  } else {
    next({ name: "Login" }); // Chuyển hướng đến trang đăng nhập
  }
};

const checkManage = async (matched, next) => {
  const data = await API().get(`cpuia/?app=${matched[0].path}`);
  setPermissionApp(matched[0].name, data);
  if (!data?.can_access) {
    next({ name: "403page" }); // Chuyển hướng đến trang chính
    return;
  }
  else {
    next();
  }
};

export { checkLogout, checkManage };
