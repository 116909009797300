export default (url_root) => {
    const ROUTER_DATA_MANAGE = [
      {
        path: "/",
        name: "appManageFolderBoundary",
        meta: {
          title: "QUẢN LÝ LỚP DỮ LIỆU HÀNH CHÍNH",
          icon: "mdi mdi-database-search-outline",
          name: "Dữ liệu hành chính",
          showMenu: true,
          showNavSubMenu: false,
          belongTo: "dataManage",
          requiresAuth: true,
          cpuiaPermission: true,
        },
        props: {
          nameKCN: "Lớp dữ liệu hành chính",
        },
        component: () => import("./pages/ManageFolderBoundary.vue"),
      },
      {
        path: "/:id",
        name: "appManageFolderBoundaryDetails",
        meta: {
          title: "QUẢN LÝ LỚP DỮ LIỆU HÀNH CHÍNH",
          icon: "mdi mdi-database-search-outline",
          name: "Thư mục",
          showMenu: false,
          showNavSubMenu: false,
          belongTo: "dataManage",
          requiresAuth: true,
          cpuiaPermission: true,
        },
        props: {
          nameKCN: "Lớp dữ liệu hành chính",
        },
        component: () => import("./pages/ManageFolderBoundary.vue"),
      },
    ];
  
    ROUTER_DATA_MANAGE.map((router) => {
      router.path = url_root ? "/" + url_root + router.path : router.path;
    });
    return ROUTER_DATA_MANAGE;
  };
  