import axios from "axios";
import { SERVER_URL } from "@/helpers/utils/config_system.js"

export const BASE_URL = SERVER_URL;
export const MAP_KEY = 'fda0ef247812a0f208b654c89a8f9308';
export const VIEW_BOX = '21.263437333993423,106.14833445172829,21.319178112767688,106.23313516582365';

export default axios.create({
  baseURL: BASE_URL + "/api",
});
