import APP_BOUNDARIES_ROUTER from "@/app_boundary/router.js";
import APP_COOPERATIVE_ROUTER from "@/app_cooperative/router.js"
import APP_MATERIALAREA_ROUTER from "@/app_materialarea/router.js"
import APP_PRODUCT_ROUTER from "@/app_product/router.js"
import APP_IMPORT_ROUTER from "@/app_import_excel/router.js"
import APP_SURVEY_ROUTER from "@/app_import_survey/router.js"

export default (url_root) => {
    const ROUTER_DATA_MANAGE = [
        ...APP_BOUNDARIES_ROUTER("boundaries"),
        ...APP_COOPERATIVE_ROUTER("cooperative"),
        ...APP_MATERIALAREA_ROUTER("materialarea"),
        ...APP_PRODUCT_ROUTER("product"),
        ...APP_IMPORT_ROUTER("import"),
        ...APP_SURVEY_ROUTER("survey")
    ]

    ROUTER_DATA_MANAGE.map(router => {
        router.path = url_root ? '/' + url_root + router.path : router.path
    })
    return ROUTER_DATA_MANAGE
}