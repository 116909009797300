
export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "appReport",
      meta: {
        title: "QUẢN LÝ BÁO CÁO",
        icon: 'bx bxs-file-doc',
        name: "Báo cáo",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {},
      component: () => import("@/app_report/pages/ManageReport.vue"),
    },
    {
      path: "/",
      name: "appReport",
      meta: {
        title: "QUẢN LÝ BÁO CÁO",
        icon: 'ri-bar-chart-grouped-fill',
        name: "Tạo mẫu báo cáo",
        showMenu: false,
        showNavSubMenu: false,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {},
      component: () => import("@/app_report/pages/ManageReport.vue"),
    },
    {
      path: "/export-product",
      name: "appReportHTX",
      meta: {
        title: "QUẢN LÝ BÁO CÁO",
        icon: 'bx bxs-file-doc',
        name: "Thống kê sản phẩm Xuất khẩu Chủ lực, OCOP",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {},
      component: () => import("@/app_report/pages/manage_report/ManageReport.vue"),
    },
    {
      path: "/export-cooperative",
      name: "appReportCoopByProvince",
      meta: {
        title: "QUẢN LÝ BÁO CÁO",
        icon: 'bx bxs-file-doc',
        name: "Thống kê HTX theo Tỉnh",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {},
      component: () => import("@/app_report/pages/manage_report_coop_province/ManageReport.vue"),
    }
  ];

  ROUTER_DATA_MANAGE.map(router => {
    router.path = url_root ? '/' + url_root + router.path : router.path
  })
  return ROUTER_DATA_MANAGE
}