import { BASE_URL } from "./axiosHttp.js";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';
import userState from "../../state/dataUser.js";
import { watch } from "vue";

const PROXY = BASE_URL + "/api";
export let UerCustomer = ""

watch(userState, () => {
    UerCustomer = userState.value.token
})

export const useFetch = async function (fullApiUrl) {
    const apiUrl = validUrl(`${fullApiUrl}`)
    try {
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${UerCustomer}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return
    }
};
export const usePost = async function (url, dataPost) {
    const apiUrl = validUrl(`${PROXY}/${url}`)
    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Accept": "*/*",
                "Authorization": `Bearer ${UerCustomer}`,
            },
            body: dataPost,
        });
        /*const data = await response.json();
        return data;*/
        return response;
    } catch (error) {
        return
    }
};
export const usePatch = async function (url, dataPost) {
    const apiUrl = validUrl(`${PROXY}/${url}`)
    try {
        const response = await fetch(apiUrl, {
            method: "PATCH",
            headers: {
                "Accept": "*/*",
                "Authorization": `Bearer ${UerCustomer}`,
            },
            body: dataPost,
        });
        return response;
    } catch (error) {
        return
    }
};


export const useDelete = async function (url) {
    const apiUrl = validUrl(`${PROXY}/${url}`)
    try {
        const response = await fetch(apiUrl, {
            method: "DELETE",
            headers: {
                "Accept": "*/*",
                "Authorization": `Bearer ${UerCustomer}`,
            },
        });
        return response
    } catch (error) {
        return
    }
};

export const fetchGet = async function (url) {
    const apiUrl = validUrl(`${PROXY}/${url}`)
    try {
        const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${UerCustomer}`,
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        return
    }
};
export const fetchDelete = async function (url, toast = '') {
    const apiUrl = validUrl(`${PROXY}/${url}`)
    try {
        const response = await fetch(apiUrl, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${UerCustomer}`,
            },
        });
        if (!toast) {
            if (response.ok) {
                toast.success("Xoá thành công!", {
                    theme: 'colored',
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            } else {
                toast.error("Xoá thất bại!", {
                    theme: 'colored',
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
        return response;
    } catch (error) {
        return
    }
};
export const postAPI = async function (url, dataPost) {
    const apiUrl = validUrl(`${PROXY}/${url}`)
    try {
        const response = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "Accept": "*/*",
                "Authorization": `Bearer ${UerCustomer}`,
            },
            body: dataPost,
        });
        if (response.ok) {
            toast.success("Đã thêm thành công!", {
                theme: 'colored',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            toast.error("Error: Chưa thể thêm mới!", {
                theme: 'colored',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return
    }
};
export const updateAPI = async function (url, dataPut) {
    const apiUrl = validUrl(`${PROXY}/${url}`)
    try {
        const response = await fetch(apiUrl, {
            method: "PATCH",
            headers: {
                "Accept": "*/*",
                "Authorization": `Bearer ${UerCustomer}`,
            },
            body: dataPut,
        });
        if (response.ok) {
            toast.success("Cập nhật thành công!", {
                theme: 'colored',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } else {
            toast.error("Cập nhật thất bại!", {
                theme: 'colored',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        }
        const data = await response.json();
        return data;
    } catch (error) {
        return
    }
};


const validUrl = (url) => {
    var regex = /([^:]\/)\/+(?!\/|$)/g;
    var hasParams = /\?/.test(url);
    var endsWithSlash = /\/$/.test(url);
  
    var normalizedURL = url.replace(regex, "$1");
    if (hasParams && endsWithSlash) {
      normalizedURL = normalizedURL.slice(0, -1);
    } else if (!hasParams && !endsWithSlash) {
      normalizedURL += "/";
    }  
    return normalizedURL;
  }
