import "@/app_portal/assets/custom-portal.scss";
const ROUTERMAPCONTROL = [
  {
    path: "/ban-do",
    name: "MapControl",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/map-type/:typePOI",
    name: "MapViewTypePOI",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/data-layer",
    name: "MapDataLayer",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/setting",
    name: "MapSetting",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/direct",
    name: "MapDirect",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
    props: {
      feature: "direct",
    },
  },
  {
    path: "/ban-do/details-layer",
    name: "MapDetailsLayer",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/detail-project",
    name: "MapDetailProjectView",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/style-layer",
    name: "MapStyleLayer",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/show-de",
    name: "MapShowDe",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/search-plus",
    name: "MapSearchPlus",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/quick-search",
    name: "MapQuickSearch",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/show-text-search",
    name: "MapShowQickSearch",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/static-data",
    name: "MapStaticData",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/project-view",
    name: "MapProjectView",
    meta: {
      title: "BẢN ĐỒ VÙNG NGUYÊN LIỆU",
    },
    component: () => import("./views/map/main_map/MapControl.vue"),
  },
  {
    path: "/ban-do/so-sanh-thong-tin",
    name: "MapCompareMap",
    meta: {
      title: "So sánh bản đồ",
    },
    component: () => import("./views/map/main_map/ComperaMap.vue"),
  },
];

const ARRROUTER = [
  {
    path: "/",
    name: "Home",
    meta: { title: "Trang chủ" },
    component: () => import("./views/home/HomePage.vue"),
  },
  {
    path: "/home",
    name: "HomePage",
    meta: { title: "Trang chủ" },
    component: () => import("./views/home/HomePage.vue"),
  },
  {
    path: "/introduction",
    name: "AboutUsPage",
    meta: { title: "Về chúng tôi" },
    component: () => import("./views/introduce/AboutUs.vue"),
  },
  {
    path: "/cooperative/list",
    name: "CooperativePublic",
    meta: { title: "Hợp tác xã" },
    component: () => import("./views/cooperative/Cooperative.vue"),
  },
  {
    path: "/map",
    name: "MapPortal",
    meta: {
      title: "BẢN ĐỒ HỢP TÁC XÃ VIỆT NAM",
    },
    component: () => import("./views/map/map_portal/MapPortal.vue"),
  },
  {
    path: "/dashboard",
    name: "Consultation",
    meta: { title: "Dashboard" },
    component: () => import("./views/infomation/consultation/Consultation.vue"),
  },
  {
    path: "/contact",
    name: "ContactPage",
    meta: { title: "Liên hệ" },
    component: () => import("./views/contact/Contact.vue"),
  },
  {
    path: "/404-page",
    name: "404page",
    meta: { title: "Không tồn tại" },
    component: () => import("./views/404/404-basic.vue"),
  },
  {
    path: "/403-page",
    name: "403page",
    meta: { title: "Error" },
    component: () => import("./views/404/403-basic.vue"),
  },
  // Fallback route để xử lý các đường dẫn không khớp
  // {
  //   path: "/:pathMatch(.*)*",
  //   name: "NotFound",
  //   component: () => import("./views/404/404-basic.vue"),
  //   // Component hiển thị trang lỗi 404
  // },
  {
    path: "/apps",
    name: "AppsPage",
    meta: { title: "Ứng dụng" },
    component: () => import("./views/applications/AppLayout.vue"),
  },
  {
    path: "/profile/detail",
    name: "Profile",
    meta: { title: "Thông tin cá nhân" },
    component: () => import("./views/profile/Profile.vue"),
  },
  {
    path: "/profile/edit",
    name: "ProfileUpdate",
    meta: { title: "Cập nhật thông tin cá nhân" },
    component: () => import("./views/profile/setting.vue"),
  },
  {
    path: "/change-password",
    name: "ProfileChangePassword",
    meta: { title: "Thay đổi mật khẩu" },
    component: () => import("./views/profile/ChangePW.vue"),
  },
  ...ROUTERMAPCONTROL,
];

export default function (path) {
  return ARRROUTER.map((item) => {
    if (path) item.path = "/" + path + item.path;
    return item;
  });
}
