export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "appSurvey",
      meta: {
        title: "PHIẾU KHẢO SÁT",
        icon: "ri-team-line",
        name: "Phiếu khảo sát",
        showMenu: true,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: false,
      },
      props: {
        nameKCN: "Phiếu khảo sát",
      },
      component: () => import("./pages/ManageImport.vue"),
    },
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
