export default (url_root) => {
  const LOG_ROUTER = [
    {
      path: "/notifications",
      name: "appSendNotification",
      meta: {
        title: "QUẢN LÝ THÔNG BÁO",
        icon: "bx bx-bell",
        name: "Thông báo",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "THÔNG BÁO",
      },
      component: () =>
        import("../app_notification/pages/manage_send_noti/ManageSendNoti.vue"),
    },
    {
      path: "/notifications/notifications-send",
      name: "manageSendNotification",
      meta: {
        title: "QUẢN LÝ THÔNG BÁO HỆ THỐNG",
        icon: "bx bx-bell",
        name: "Thông báo hệ thống",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "THÔNG BÁO",
      },
      component: () => import("../app_notification/pages/manage_send_noti/ManageSendNoti.vue"),
    },
    {
      path: "/notifications/mail-send",
      name: "manageSendMail",
      meta: {
        title: "QUẢN LÝ THÔNG BÁO MAIL",
        icon: "bx bx-bell",
        name: "Thông báo Email",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "THÔNG BÁO",
      },
      component: () => import("../app_notification/pages/manage_send_mail/ManageSendMail.vue"),
    }
  ];

  LOG_ROUTER.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return LOG_ROUTER;
};
