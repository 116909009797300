export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "appManageFolder",
      meta: {
        title: "QUẢN LÝ LỚP DỮ LIỆU ĐỐI TƯỢNG",
        icon: "mdi mdi-database-marker-outline",
        name: "Dữ liệu đối tượng",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "Lớp dữ liệu đối tượng",
      },
      component: () => import("./pages/ManageFolder.vue"),
    },
    {
      path: "/:id",
      name: "appManageFolderDetails",
      meta: {
        title: "QUẢN LÝ LỚP DỮ LIỆU ĐỐI TƯỢNG",
        icon: "mdi mdi-database-marker-outline",
        name: "Thư mục",
        showMenu: false,
        showNavSubMenu: false,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "Lớp dữ liệu đối tượng",
      },
      component: () => import("./pages/ManageFolder.vue"),
    },
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
