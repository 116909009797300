export default (url_root) => {
    const ROUTER_DATA_MANAGE = [
        {
            path: "/apps",
            name: "appManageApp",
            meta: {
                title: "QUẢN LÝ ỨNG DỤNG",
                icon: "ri-apps-2-line",
                name: "Ứng dụng",
                showMenu: true,
                showNavSubMenu: true,
                belongTo: "systemManage",
                requiresAuth: true,
                cpuiaPermission: true,
            },
            props: {
                nameKCN: "ỨNG DỤNG",
            },
            component: () =>
                import("@/app_store/pages/manage_app/ManageApp.vue"),
        },
        {
            path: "/apps/:id",
            name: "appManageAppDetail",
            meta: {
                title: "THÔNG TIN ỨNG DỤNG",
                icon: "ri-apps-2-line",
                name: "Ứng dụng",
                showMenu: false,
                showNavSubMenu: false,
                belongTo: "systemManage",
                requiresAuth: true,
                cpuiaPermission: true,
            },
            props: {
                nameKCN: "ỨNG DỤNG",
            },
            component: () =>
                import("@/app_store/pages/manage_app/DetailApp.vue"),
        },
        {
            path: "/apps/edit/:id",
            name: "appManageAppEdit",
            meta: {
                title: "CẬP NHẬT ỨNG DỤNG",
                icon: "ri-apps-2-line",
                name: "Ứng dụng",
                showMenu: false,
                showNavSubMenu: false,
                belongTo: "systemManage",
                requiresAuth: true,
                cpuiaPermission: true,
            },
            props: {
                nameKCN: "ỨNG DỤNG",
            },
            component: () =>
                import("@/app_store/pages/manage_app/EditApp.vue"),
        }
    ];

    ROUTER_DATA_MANAGE.map(router => {
        router.path = url_root ? '/' + url_root + router.path : router.path
    })
    return ROUTER_DATA_MANAGE
}