import "@/app_portal/assets/custom-portal.scss";
const ROUTERMAPCONTROL = [
  {
    path: "/report/list",
    name: "reportPublic",
    meta: { title: "Thống kê" },
    component: () => import("./Layout.vue"),
  },
  {
    path: "/report/detail/:id",
    name: "mapReportDetail",
    meta: { title: "Bản đồ báo cáo" },
    component: () => import("./map_report/MapPortal.vue"),
  }
];

export default function (path) {
  return ROUTERMAPCONTROL.map((item) => {
    if (path) item.path = "/" + path + item.path;
    return item;
  });
}
