export default (url_root) => {
  const LOG_ROUTER = [
    {
      path: "/log-auth",
      name: "appLogAuth",
      meta: {
        title: "QUẢN LÝ NHẬT KÝ",
        icon: "ri-file-text-line",
        name: "Nhật ký",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "NHẬT KÝ",
      },
      component: () =>
        import("../app_log/pages/manage_dashboard/ManageDashboard.vue"),
    },
    {
      path: "/log-auth/dashboard",
      name: "manageLogDashboard",
      meta: {
        title: "QUẢN LÝ NHẬT KÝ",
        icon: "ri-file-text-line",
        name: "Dashboard",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "NHẬT KÝ",
      },
      component: () => import("../app_log/pages/manage_dashboard/ManageDashboard.vue"),
    },
    {
      path: "/log-auth/log",
      name: "manageLog",
      meta: {
        title: "QUẢN LÝ NHẬT KÝ ĐĂNG NHẬP",
        icon: "ri-file-text-line",
        name: "Lịch sử đăng nhập",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "NHẬT KÝ",
      },
      component: () => import("../app_log/pages/manage_log/ManageLog.vue"),
    }
  ];

  LOG_ROUTER.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return LOG_ROUTER;
};
