export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "appMap",
      meta: {
        title: "QUẢN LÝ BẢN ĐỒ",
        icon: "ri-road-map-line",
        name: "Bản đồ",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "BẢN ĐỒ",
      },
      component: () => import("@/app_map/pages/manage_map/ManageMap.vue"),
    },
    {
      path: "/",
      name: "appMap",
      meta: {
        title: "QUẢN LÝ BẢN ĐỒ",
        icon: "ri-road-map-line",
        name: "Danh sách",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "BẢN ĐỒ",
      },
      component: () => import("@/app_map/pages/manage_map/ManageMap.vue"),
    },
    {
      path: "/map-type",
      name: "appMapType",
      meta: {
        title: "QUẢN LÝ LOẠI BẢN ĐỒ",
        icon: "ri-road-map-line",
        name: "Phân loại",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "BẢN ĐỒ",
      },
      component: () =>
        import("@/app_map/pages/manage_map_type/ManageMapType.vue"),
    },
    {
      path: "/:id",
      name: "manageBuildMap",
      meta: {
        title: "QUẢN LÝ BẢN ĐỒ",
        icon: "ri-road-map-line",
        name: "Bản đồ",
        showMenu: false,
        showNavSubMenu: false,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "BẢN ĐỒ",
      },
      component: () =>
        import("@/app_map/pages/manage_map/build_map/ManageBuildMap.vue"),
    },
    {
      path: "/style",
      name: "manageMapStyle",
      meta: {
        title: "QUẢN LÝ STYLE MAP",
        icon: "ri-road-map-line",
        name: "Style map",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "dataManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "BẢN ĐỒ",
      },
      component: () =>
        import("@/app_map/pages/manage_map_style/ManageMapStyle.vue"),
    },
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
