export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "appMaterialarea",
      meta: {
        title: "QUẢN LÝ VÙNG SẢN XUẤT",
        icon: "ri-plant-line",
        name: "Vùng sản xuất",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "VÙNG SẢN XUẤT",
      },
      component: () =>
        import(
          "../app_materialarea/pages/manage_material/ManageMaterialArea.vue"
        ),
    },
    {
      path: "/material-area",
      name: "manageMaterialarea",
      meta: {
        title: "QUẢN LÝ VÙNG SẢN XUẤT",
        icon: "ri-truck-line",
        name: "Danh sách",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "VÙNG SẢN XUẤT",
      },
      component: () =>
        import(
          "../app_materialarea/pages/manage_material/ManageMaterialArea.vue"
        ),
    },
    {
      path: "/type",
      name: "manageMaterialType",
      meta: {
        title: "QUẢN LÝ LOẠI VÙNG SẢN XUẤT",
        icon: "ri-pages-line",
        name: "Phân loại",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "VÙNG SẢN XUẤT",
      },
      component: () =>
        import(
          "@/app_materialarea/pages/manage_material_type/manage.vue"
        ),
    },
    {
      path: "/tags",
      name: "manageMaterialTags",
      meta: {
        title: "QUẢN LÝ TAGS VÙNG SẢN XUẤT",
        icon: "ri-pages-line",
        name: "Tags",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "VÙNG SẢN XUẤT",
      },
      component: () =>
        import(
          "@/app_materialarea/pages/manage_material_tags/manage.vue"
        ),
    },
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
