export const portalRouter = "";
export const dataMangeRouter = "manage";
export const systemMangeRouter = "manage/settings";

import APP_PORTAL from "../app_portal/router.js";
import APP_DATA_MANAGE from "../app_manage/router.js";
import APP_SYSTEM_MANAGE from "../app_system_manage/router.js";
import APP_CORE_DATA_MANAGE from "@/app_data_manage/router.js";
import APP_AUTH from "../app_auth/router.js";
import APP_LOG from "../app_log/router.js";
import APP_SUBCRIBE from "../app_subcribe/router.js";
import APP_NOTIFICATION from "../app_notification/router.js";
import APP_MANAGE_APP from "@/app_store/router.js";

import APP_COOPERATIVE_ROUTER from "@/app_cooperative/pages/manage_coop_staff/router.js";
import APP_PRODUCT_ROUTER from "@/app_product/pages/manage_product_staff/router.js";
import APP_MATERIAL_ROUTER from "@/app_materialarea/pages/manage_material_staff/router.js";
import APP_CONFIG_MAP_REPORT from "@/app_config_map_report/router.js";
import APP_ROUTER_REPORT_PUBLIC from "@/app_report/pages/public/router.js";
import DEMO_COMPONENT_ROUTER from "@/base/components/router.js";
export default [
  ...APP_PORTAL(portalRouter),
  ...DEMO_COMPONENT_ROUTER("base/components"),
  ...APP_SYSTEM_MANAGE(systemMangeRouter),
  ...APP_CORE_DATA_MANAGE(dataMangeRouter),
  ...APP_DATA_MANAGE(dataMangeRouter),
  ...APP_AUTH(),
  ...APP_LOG(dataMangeRouter),
  ...APP_SUBCRIBE(dataMangeRouter),
  ...APP_NOTIFICATION(dataMangeRouter),
  ...APP_MANAGE_APP(dataMangeRouter),
  ...APP_COOPERATIVE_ROUTER("cooperative"),
  ...APP_PRODUCT_ROUTER("product"),
  ...APP_MATERIAL_ROUTER("material"),
  ...APP_CONFIG_MAP_REPORT(dataMangeRouter),
  ...APP_ROUTER_REPORT_PUBLIC(portalRouter),
];
