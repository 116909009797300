
export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "ManageMaterialAreaStaff",
      meta: {
        title: "Quản lý vùng sản xuất",
        icon: 'ri-pages-line',
        name: "Vùng sản xuất",
        showMenu: false,
        showNavSubMenu: false,
        belongTo: "manage"
      },
      props: {},
      component: () => import("./ManageMaterialArea.vue"),
    }
  ];

  ROUTER_DATA_MANAGE.map(router => {
    router.path = url_root ? '/' + url_root + router.path : router.path
  })
  return ROUTER_DATA_MANAGE
}
