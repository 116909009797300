import userState from "../../state/dataUser";
import { checkLogout, checkManage } from "./page_case.js";

const RouterCheck = async (to, from, next) => {
  const { name, params, matched } = to;
  const requiresAuth = matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !userState.value?.id) {
    next({ name: "Login", query: { redirect: name, ...params } });
  } else if (userState.value?.id) {
    const authRoutes = ["Login", "Register", "ResetPassword"];
    if (authRoutes.includes(name)) {
      next({ name: "Home" });
    } else if (name === "Home") {
      next();
    } else if (name === "Logout") {
      checkLogout(requiresAuth, next);
    } else if (matched.some((record) => record.meta.cpuiaPermission)) {
      await checkManage(matched, next);
    } else {
      next();
    }
  } else {
    next();
  }
};

export { RouterCheck };
