export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/map-report",
      name: "appConfigMapReport",
      meta: {
        title: "THIẾT LẬP THỐNG KÊ",
        icon: "bx bxs-report",
        name: "Thiết lập thống kê",
        showMenu: true,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "THIẾT LẬP THỐNG KÊ",
      },
      component: () =>
        import("@/app_config_map_report/pages/config_map_report/Manage.vue"),
    },
    {
      path: "/dynamic-report",
      name: "appConfigReport",
      meta: {
        title: "THIẾT LẬP BÁO CÁO",
        icon: "bx bxs-report",
        name: "Thiết lập báo cáo",
        showMenu: true,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: false,
        cpuiaPermission: false,
      },
      props: {
        nameKCN: "THIẾT LẬP BÁO CÁO",
      },
      component: () =>
        import("@/app_config_map_report/pages/dynamic_report/Manage.vue"),
    }
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
