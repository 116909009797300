// import APP_BOUNDARY_DATA_ROUTER from "@/app_boundary_data/router.js";
import APP_REPORT_MANAGE_ROUTER from "@/app_report/router.js";
import APP_MAP_MANAGE_ROUTER from "@/app_map/router.js";
import APP_USER_MANAGE from "../app_user/router.js";
import APP_ORGANIZATION_MANAGE from "@/app_organization/router.js"
import APP_FOLDER_MANAGE from "../app_folder/router.js";
import APP_FOLDER_BOUNDARY_MANAGE from "../app_boundary_data/router";

export default (url_root) => {
    const ROUTER_DATA_MANAGE = [
        ...APP_FOLDER_MANAGE('folder'),
        ...APP_FOLDER_BOUNDARY_MANAGE("boundary-data"),
        ...APP_MAP_MANAGE_ROUTER("map"),
        ...APP_REPORT_MANAGE_ROUTER("report"),
        ...APP_USER_MANAGE("accounts"),
        ...APP_ORGANIZATION_MANAGE("organizations"),
    ]

    ROUTER_DATA_MANAGE.map(router => {
        router.path = url_root ? '/' + url_root + router.path : router.path
    })
    return ROUTER_DATA_MANAGE
}
