
export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/detail/:id",
      name: "managePublichMaterial",
      meta: {
        title: "manage-public-staff",
        icon: 'ri-pages-line',
        name: "Vùng sản xuất",
        showMenu: false,
        showNavSubMenu: false,
        belongTo: "manage"
      },
      props: {},
      component: () => import("@/app_materialarea/pages/manage_material_staff/Material.vue"),
    },
  ];

  ROUTER_DATA_MANAGE.map(router => {
    router.path = url_root ? '/' + url_root + router.path : router.path
  })
  return ROUTER_DATA_MANAGE
}
