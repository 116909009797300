export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "organization",
      meta: {
        title: "QUẢN LÝ TỔ CHỨC",
        icon: "ri-community-line",
        name: "Tổ chức",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "TỔ CHỨC",
      },
      component: () =>
        import("./pages/manage_organization/ManageOrganization.vue"),
    },
    {
      path: "/organization",
      name: "manageOrganization",
      meta: {
        title: "QUẢN LÝ TỔ CHỨC",
        icon: "ri-community-line",
        name: "Tổ chức",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "TỔ CHỨC",
      },
      component: () =>
        import("./pages/manage_organization/ManageOrganization.vue"),
    },
    {
      path: "/department",
      name: "manageDepartment",
      meta: {
        title: "QUẢN LÝ TỔ CHỨC PHÒNG BAN",
        icon: "ri-community-line",
        name: "Phòng ban",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "TỔ CHỨC",
      },
      component: () => import("./pages/manage_department/ManageDepartment.vue"),
    },
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
