export default (url_root) => {
  const LOG_ROUTER = [
    {
      path: "/subcribe",
      name: "appSubcribe",
      meta: {
        title: "QUẢN LÝ ĐĂNG KÝ",
        icon: "ri-chat-check-line",
        name: "Đăng ký",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "ĐĂNG KÝ",
      },
      component: () =>
        import("../app_subcribe/pages/manage_subcribe/ManageSubcribe.vue"),
    },
    {
      path: "/subcribe",
      name: "manageSubcribe",
      meta: {
        title: "QUẢN LÝ ĐĂNG KÝ",
        icon: "ri-chat-check-line",
        name: "Đăng ký",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "systemManage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "ĐĂNG KÝ",
      },
      component: () => import("../app_subcribe/pages/manage_subcribe/ManageSubcribe.vue"),
    }
  ];

  LOG_ROUTER.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return LOG_ROUTER;
};
