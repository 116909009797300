export default (url_root) => {
  const ROUTER_DATA_MANAGE = [
    {
      path: "/",
      name: "appCooperative",
      meta: {
        title: "QUẢN LÝ HỢP TÁC XÃ",
        icon: "ri-team-line",
        name: "Hợp tác xã",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "HỢP TÁC XÃ",
      },
      component: () =>
        import("@/app_cooperative/pages/manage_coop/ManageCooperative.vue"),
    },
    {
      path: "/",
      name: "appCooperative",
      meta: {
        title: "QUẢN LÝ HỢP TÁC XÃ",
        icon: "ri-team-line",
        name: "Danh sách",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "HỢP TÁC XÃ",
      },
      component: () =>
        import("@/app_cooperative/pages/manage_coop/ManageCooperative.vue"),
    },
    {
      path: "/type",
      name: "appCooptypecate",
      meta: {
        title: "QUẢN LÝ PHÂN LOẠI HỢP TÁC XÃ",
        icon: "ri-pages-line",
        name: "Phân loại",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: { nameKCN: "HỢP TÁC XÃ" },
      component: () =>
        import(
          "@/app_cooperative/pages/manage_cooptype/manage.vue"
        ),
    },
    {
      path: "/tags",
      name: "manageCooptypecateTags",
      meta: {
        title: "QUẢN LÝ TAGS HỢP TÁC XÃ",
        icon: "ri-pages-line",
        name: "Tags",
        showMenu: false,
        showNavSubMenu: true,
        belongTo: "manage",
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: { nameKCN: "TAGS HỢP TÁC XÃ" },
      component: () =>
        import(
          "@/app_cooperative/pages/manage_cooptags/Manage.vue"
        ),
    },
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
